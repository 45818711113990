import styled from 'styled-components';

export const StyledSelectedTab = styled.div`
    font-family: Overpass;
    font-size: 20px;
    font-weight: 300;
    line-height: 28px;
    text-align: center;
    border-bottom: 1px solid #F0F0F6;
`;

export const StyledSelectedSection = styled.div`
    display:flex;
    flex-direction: row;
    & > div {
        width: 50%;
    }
`;

export const StyledColumn = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #F0F0F6;
    padding: 12px 0;
    align-items: center;
    font-family: Overpass;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    gap: 20px;

    &:last-child {
        border-bottom: none;
    }
`;

export const StyledIndex = styled.div`
    width: 10px;
`;

export const StyledAmount = styled.div`
    width: 80px;
    text-align: right;
`;

export const StyledTeamName = styled.div`
    flex: 1 1; 
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
    overflow: hidden;
`;

export const StyledHeading = styled.div`
    font-family: Overpass;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    text-align: left;
    color: #161719;
`;

export const StyledLeaderboardContainer = styled.div`
    display: flex;
    padding: 48px;
    flex-direction: column;
    width: 100%;
    gap:16px;
`;

export const StyledSeeMoreButton = styled.div`
    border: 1px solid #161719;
    padding: 8px 16px 8px 16px;
    font-family: Overpass;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.26px;
    width: fit-content;
    border-radius: 20px;
    margin-left: auto;
    margin-right: auto;
`;

export const StyledFilter = styled.select`
    border: none;
    width: fit-content;
    outline: none;
    font-family: Overpass;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
`;

export const StyledFilterContainer = styled.div`
    border-bottom: 1px solid #F0F0F6;
    text-align: center;
    padding: 0 0 16px;
`;

export const StyledColumnContainer = styled.div<{rows: number}>`
    position: relative;
    min-height: calc(${props => props.rows} * 73px); 
    border-bottom: 1px solid #F0F0F6;
    text-align: center;
    padding: 0 0 16px;
`;
