import { useEffect, useRef, useState } from "react";
import { StyledAmount, StyledColumn, StyledColumnContainer, StyledFilter, StyledFilterContainer, StyledHeading, StyledIndex, StyledLeaderboardContainer, StyledSeeMoreButton, StyledSelectedSection, StyledSelectedTab, StyledTeamName } from "./Leaderboard.style";
import { getApiUrl, getEnvironment, getUrlLocale } from "components/utils/helper";
import Throbber from "components/common/Throbber/Throbber";

type NetworkType = "team" | "member" | "both";
interface Props {
    title: string,
    
    networkId: string,

    showIndividualsLeaderboard: boolean,

    showMoveValues: boolean,
    showAmountValues: boolean,
    
    countryCode?: string,
    countryCodes?: string[],

    campaignYears?: string[],

    teamCount: number,
}

const Leaderboard: React.FC<Props> = (props) => {
    const networkType: NetworkType = props.showIndividualsLeaderboard ? "both" : "team";

    const [sourceData, setSourceData] = useState<any[]>([]);

    const [selectedType, setSelectedType] = useState<NetworkType>('team');
    const [selectedCountry, setSelectedCountry] = useState(props.countryCode);
    const [countryCodes, setCountryCodes] = useState<string[]>([]);

    const [selectedYear, setSelectedYear] = useState<string>(props.campaignYears ? props.campaignYears[0] : '');
    
    const [showThrobber, setShowThrobber] = useState(true);

    const networkRef = useRef(null);

    const teamCount = props.teamCount || 10;

    useEffect(() => {
        (async () => {
            if (!props.countryCode) {
                // work out the networks country code
                const networkData = await getNetworkData();

                setSelectedCountry(networkData.countryOrigin);

                if (!props.countryCodes) {
                    setCountryCodes([networkData.countryOrigin]);
                }
            }
            else {
                if (!props.countryCodes) {
                    setCountryCodes([props.countryCode]);
                }
            }
        })();

        updateLeaderboardData();
    }, [])

    useEffect(()=> {
        updateLeaderboardData();
    }, [selectedType, selectedYear, selectedCountry])

    const updateLeaderboardData = async () => {
        setShowThrobber(true);
        
        const result = await getLeaderboardData();

        setSourceData(result);

        setShowThrobber(false);
    };

    const getNetworkData = async (): Promise<any> => {
        if (networkRef.current) {
            return networkRef.current;
        }

        if (getEnvironment() === 'local') {
            await new Promise(resolve => setTimeout(resolve, 100));

            networkRef.current =  require("./networkDummy.json");

            return networkRef.current;
        }
        const networkUrl = getApiUrl(`?id=${props.networkId}`, '', 'team-member');
        
        const result = await fetch(networkUrl, {
            method: 'GET',
            mode: 'no-cors',
            headers: {
                "Content-Type": "application/json"
            },
        });

        networkRef.current = await result.json();

        return networkRef.current;
    }

    const getLeaderboardData = async () => {
        if (getEnvironment() === 'local') {
            if (selectedType === 'member') {
                await new Promise(resolve => setTimeout(resolve, 5000));
                const result = require("./networkMemberDummy.json");

                return result.data.slice(0, teamCount);
            }

            await new Promise(resolve => setTimeout(resolve, 5000));
            const result = require("./networkTeamDummy.json");

            return result.data.slice(0, teamCount);
        }

        const newURL = getApiUrl(`?networkId=${props.networkId}&page=0&limit=${teamCount}&type=${selectedType}&countryCode=${selectedCountry}`, selectedCountry, 'network-leaderboard');
        
        const result = await fetch(newURL, {
            method: 'GET',
            mode: 'no-cors',
            headers: {
                "Content-Type": "application/json"
            },
        });

        const results = await result.json();

        if (!results || !results.data) {
            return [];
        }

        const data = results.data;

        if (data.length > teamCount) {
            return data.slice(0, teamCount);
        }

        return data;
    }

    const changeSelectedType = (type: NetworkType) => {
        setSelectedType(type);
    }

    const changeSelectedCountry = (country: string) => {
        setSelectedCountry(country);
    }


    const selectedTypeSection = () => {
        if (networkType !== 'both') {
            return (
                <StyledSelectedTab> {networkType} </StyledSelectedTab>
            )
        } else {
            return (
                <>
                    <StyledSelectedSection>
                        <StyledSelectedTab style={{borderBottom: selectedType ==='member'? '2px solid black' : "1px solid #F0F0F6"}} onClick={()=>changeSelectedType('member')}> Top movers </StyledSelectedTab> 
                        <StyledSelectedTab style={{borderBottom: selectedType ==='team'? '2px solid black' : "1px solid #F0F0F6"}} onClick={()=>changeSelectedType('team')}> Top teams </StyledSelectedTab>
                    </StyledSelectedSection>
                    
                </>
            )
        }
    }

    const columnData = () => {
        return (
                    <div>
                        {sourceData.map((each, index)=>{
                            const name = each.name || `${each.member_firstname ?? ""} ${each.member_lastname ?? ""}`;

                            const showMoveValues = props.showMoveValues;

                            const amount = showMoveValues ? `${each.move_stats.moves}` : `${each.amount_converted.toFixed(0)} AUD`;
                            
                            return (
                                <StyledColumn>
                                    <StyledIndex>{index+1}</StyledIndex>
                                    <img src="https://au.movember.com/uploads/team-profile/e330a9a6e1bbf652da971ea5ed099ca6-654601b119789-thumb.jpg" alt="" width="48px" />
                                    <StyledTeamName>{name} </StyledTeamName>
                                    <StyledAmount>{amount}</StyledAmount>
                                </StyledColumn>
                            )
                        })}

                    </div>
        )
    }

    const dropdownFilter = () => {
        if (!countryCodes || countryCodes.length <= 1) {
            return <></>;
        }

        return (
            <StyledFilterContainer>
                <StyledFilter name="country" id="country" onChange={(e: any) => changeSelectedCountry(e.target.value)}>
                    { countryCodes.map(code => <option selected={selectedCountry == code} value={code}>{code}</option>) }
                </StyledFilter>
            </StyledFilterContainer>
        )
    }

    return (
        <StyledLeaderboardContainer>
            <StyledHeading>{props.title}</StyledHeading>
            {selectedTypeSection()}
            {dropdownFilter()}
            <StyledColumnContainer rows={teamCount}>
                {columnData()}
                <Throbber active={showThrobber} />
            </StyledColumnContainer>
            <StyledSeeMoreButton>See more</StyledSeeMoreButton>

        </StyledLeaderboardContainer>
    )
}

export default Leaderboard;