import { formatVideoUrl } from 'components/utils';
import { renderImage, renderParagraph } from 'components/utils/render'
import React, { useState } from 'react'
import { ColumnComponent } from '../MultiColumn.models'
import { StandAloneImageContainer, ImageContainer, Picture, Play, Video, VideoContainer, ParagraphContainer, ButtonContainer, TallyContainer, ImageCaption } from './DynamicComponent.style';
import PlayFillIcon from 'remixicon-react/PlayFillIcon';
import { Button, Tally } from 'components/common';
import { HtmlBlock, DataForm, Leaderboard } from 'components/content/templates';


export const DynamicComponent = (component: ColumnComponent) => {
  const { type } = component;
  const [playVideo, setPlayVideo] = useState(false);
  
  const getComponent = (): React.ReactNode => {
    const componentData = component as any;
    let isIconButton = ['external', 'download', 'link', 'arrow', 'secondaryArrow'].indexOf(componentData?.btnType) >= 0;
    switch (type) {
      case 'RichTextBlock':
        return (
            <ParagraphContainer data-attribute='paragraphContainer'>
              {renderParagraph(componentData.richTextBlock)}
            </ParagraphContainer>
        );
      case 'button':
        return (
          <ButtonContainer isIconButton={isIconButton}>
            <Button isDynamicBtn {...componentData}/>
          </ButtonContainer>
            );
      case 'Leaderboard':
        return <Leaderboard  {...componentData} />;
      case 'donationTally':
        return <TallyContainer>
                  <Tally {...componentData} />
                </TallyContainer> 
      case 'image':
        return  component.hasImageOnly ?
          <StandAloneImageContainer>
            {renderImage(componentData)}
           </StandAloneImageContainer>
           :
           <>
            <ImageContainer data-attribute='imageContainer' hasHeading={component.hasHeading}>
                <Picture hasHeading={component.hasHeading}>
                  {renderImage(componentData)}
                </Picture> 
            </ImageContainer>
            {componentData.caption && <ImageCaption data-attribute='imageCaption'>{componentData.caption}</ImageCaption>}
           </>
            
        ;
      case 'video':
        return (
              <Video onClick={() => setPlayVideo(!playVideo)}>
                  {
                      playVideo ?
                      <VideoContainer src={formatVideoUrl(componentData.videoURL)} allow='autoplay'/> :
                      <>
                          {renderImage(componentData)}
                          <Play>
                            <PlayFillIcon />
                          </Play>
                      </>
                  }
              </Video> 
        );
      case 'htmlBlock':
        return <HtmlBlock {...componentData} />
      case 'dataForm':
        return <DataForm {...componentData} isInMultiColumn={true}/>
      default:
        return <></>;
    }
  }

  return (
    <React.Fragment>
      {getComponent()}
    </React.Fragment>
  )
}