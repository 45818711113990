import React, { useState, useEffect, useContext } from 'react';
import { getUrlParam, renderImage } from 'components/utils';
import ConfigContext from 'context/ConfigContext';
import { Link, useLocation } from "react-router-dom";
import { Container, Row, Col } from 'themes/MoGrid';
import { Helmet}  from "react-helmet";
import { checkNested } from 'components/utils';
import { StoriesContainer, Breadcrumb, Featured, HeroContainer, HeroImage, HeroText, FeaturedHeading, FeaturedTitle, Story, Media, Image, Play, Info, Tags, Title, Date, LoadMore } from './Stories.style';
import { pushAnyClickEvent } from '../../tracking/events';

const Stories = ({ cms, articles }) => {
    let myLocation = useLocation();
    let config = useContext(ConfigContext);
    const noInitStories = 8, noMoreStories = 6;
    const [stories, setStories] = useState([]);
    const [tagStories, setTagStories] = useState([]);
    const [page, setPage] = useState(0);
    const [tag, setTag] = useState();

    useEffect(() => {
        // Set current story tag
        const setCurrentTag = () => {
            let currentTag = null,
            urlTag = getUrlParam('tag');
            if(urlTag) {
                if(checkNested(cms, 'nav', 'siteNav', 'stories')){
                    let tagList = [...cms.nav.siteNav.stories.primary, ...cms.nav.siteNav.stories.secondary];
                    for(let item of tagList){
                        if(item.id === urlTag){
                            currentTag = item
                            break;
                        }
                    }
                }
            }
            setTag(currentTag);
        }
        setCurrentTag();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[myLocation]);

    useEffect(() => {
        const getStories = () => {
            let result = [];
            result = articles.map(article => {
                let id = article.components[0];
                let item = cms.components[id];
                item.id = id;
                item.url = article.url;
                item.title = article.title;
                item.hasVideo = storyHasVideo(item.gallery);
                return item;
            });
            result = sortStories(result);
            // Hide any story from stories list if it has specified to hide from search & SEO
            result = result.filter(story => !story.hideFromSearchNSEO);
            if(tag){
            result = result.filter(story => story.tags.findIndex(ele => ele.id === tag.id) >= 0);
            }
            setTagStories(result); // Number of stories with tag.
            return result.slice(0, noInitStories + (page*noMoreStories));
        }
        const sortStories = stories => {
            let result = stories;
            result.sort(function(a,b){
                if (a.publishedDate && b.publishedDate) {
                    return new window.Date(b.publishedDate) - new window.Date(a.publishedDate);
                }
                return 0;
            });
            return result;
        }
        const storyHasVideo = gallery => {
            let result = false;
            for(let item of gallery){
                if(item.type === 'video'){
                    result = true;
                    break;
                }
            }
            return result;
        }
        setStories(getStories());
    },[cms, articles, page, tag]);

    return (
        <Container>
            {/* React Helmet to manage meta tags in document head */}
            <Helmet>
                <meta property="og:url" content={window.location.href} />
                <meta property="og:title" content="Movember Stories Listing"/>
            </Helmet>
              <StoriesContainer>
                    <Breadcrumb>
                        {checkNested(cms, 'pages', 'stories') && <Link to={cms.pages.stories.url} 
                        onClickCapture={()=> pushAnyClickEvent({linkUrl: cms.pages.stories.url, linkText: cms.pages.stories.title})}
                        >{cms.pages.stories.title}</Link>}
                        {tag &&
                            <React.Fragment>
                                <i className="fas fa-chevron-right"/>
                                <Link to={tag.url} onClickCapture={()=> pushAnyClickEvent({linkUrl: tag.url, linkText: tag.title})}>{tag.title}</Link>
                            </React.Fragment>
                        }
                    </Breadcrumb>
                    { false && stories && stories[0] && stories[0].gallery && stories[0].gallery[0] && 
                        <Featured>
                            <HeroContainer>
                                <HeroImage href={stories[0].url + (tag ? `?tag=${tag.id}` : '')} 
                                onClickCapture={()=> pushAnyClickEvent({linkUrl: stories[0].url + (tag ? `?tag=${tag.id}` : ''), linkText: `image/${stories[0].gallery[0].alt}`})}>
                                    {renderImage(stories[0].gallery[0])}
                                </HeroImage>
                                <HeroText href={stories[0].url} onClickCapture={()=> pushAnyClickEvent({linkUrl: stories[0].url, linkText: stories[0].title})}>
                                    <FeaturedHeading>{cms.snippets['text-common-featured'].text}</FeaturedHeading>
                                    <FeaturedTitle>{stories[0].title}</FeaturedTitle>
                                </HeroText>
                            </HeroContainer>
                        </Featured>
                    }
                    <Row>
                        {stories.map((story, index) => (
                            <Col sm={index === 3 ? 6 : 4} xs={12} key={index} {...index === 4 ? {className: 'fifth-item'} : null}>
                                <Story isFifthItem={index === 4}>
                                    <Media>
                                        <Image>
                                            <Link to={story.url + (tag ? `?tag=${tag.id}` : '')} className="image"
                                            onClickCapture={()=> pushAnyClickEvent({linkUrl: story.url + (tag ? `?tag=${tag.id}` : ''), linkText: `image/${story.squareImage?.alt}`})}> 
                                                {story.squareImage && renderImage(story.squareImage, 'small')}
                                            </Link>
                                        </Image>
                                        { story.hasVideo &&
                                            <Play className="play">
                                                <Link to={`${story.url}?autoplay=1`}><i className="fas fa-play" /></Link>
                                            </Play>
                                        }
                                    </Media>
                                    <Info>
                                        <Tags>
                                            {story.tags.map((tag, i) =>
                                                <React.Fragment key={i}>
                                                    {i > 0 && <span>|</span> }
                                                    <Link to={tag.url} onClickCapture={()=> pushAnyClickEvent({linkUrl: tag.url, linkText: tag.title})}>{tag.title}</Link>
                                                </React.Fragment>
                                            )}
                                        </Tags>
                                        <Title><Link to={story.url + (tag ? `?tag=${tag.id}` : '')}
                                        onClickCapture={()=> pushAnyClickEvent({linkUrl: story.url + (tag ? `?tag=${tag.id}` : ''), linkText: story.title})}><span className="title">{story.title}</span></Link></Title>
                                        <Date>
                                            {story.publishedDate ? new window.Date(story.publishedDate).toLocaleDateString(['us', 'ca'].indexOf(config.getCountry()) >= 0 ? 'en-US' : 'en-AU', { year: 'numeric', month: 'long', day: 'numeric' }) : ''}
                                        </Date>
                                    </Info>
                                </Story>
                            </Col>
                        ))}
                </Row>
                <LoadMore>
                    {(noInitStories + (page * noMoreStories) < tagStories.length) ?
                     <button onClick={() => setPage(page+1)}>{cms.snippets['text-common-load-more'].text}</button>
                     : (page > 0) ? <button onClick={() => window.scrollTo(0,0)}>{cms.snippets['text-common-go-to-top'].text}</button> : null
                    }
                </LoadMore>
            </StoriesContainer>
        </Container>
    );
}
 
export default Stories;