import styled from "styled-components";

const StyledThrobberContainer = styled.div<{active: boolean}>`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 50;
    height: 100%;
    width: 100%;

    align-content: center;
    text-align: center;

    transform: scaleX(-1);
    opacity: 1;
    transition: opacity 0.5s, display 0.5s allow-discrete;

    background: rgba(255,255,255,0.75);
    
    @starting-style {
        opacity: 0;
    }
    
    ${props => props.active ? '' : `
        display: none;
        opacity: 0;
    `};
`;

const throbDuration = 4;
const throbWait = 0.5;
const throbSize = 128;
const throbFrames = 12;
const throbFramesUsed = 12;

const StyledThrobber = styled.div<{active: boolean}>`
    @keyframes throbbing {
        0% {
            transform: rotate(0deg) scale(1);
        }
        70% {
            transform: rotate(-1440deg) scale(1);
        }
        100% {
            transform: rotate(-1440deg) scale(1);
        }
    }
    @keyframes throbbingFrames {
        0% {
            background-position: 0px 0px;
            transform: scale(1);
        }
        30% {
            background-position: calc(${-throbFramesUsed} * ${throbSize}px) 0px;
            transform: scale(1.1);
            filter: blur(0.5px);
        }
        70% {
            background-position: 0px 0px;
            transform: scale(1);
        }
        100% {
            background-position: 0px 0px;
            transform: scale(1);
        }
    }

    @starting-style {
        opacity: 0;
        transform: rotate(0deg) scale(0);
    }

    position: relative;
    display: inline-block;

    transform: rotate(0deg) scale(1);
    animation: throbbing ${throbDuration}s ${throbWait}s forwards infinite;
    animation-timing-function: ease-in-out;
        
    ${props => props.active ? '' : `
        display: none;

        opacity: 0;
        transform: rotate(0deg) scale(0);
        animation: none;
    `};

    width: ${throbSize}px;
    height: ${throbSize}px;

    opacity: 1;

    transition: opacity ${throbWait}s, transform ${throbWait}s, display ${throbWait}s allow-discrete;

    &::after {
        content:'';

        display: block;
        width: 100%;
        height: 100%;
            
        ${props => props.active ? '' : `
            display: none;
            transform: rotate(0deg) scale(0);
            animation: none;
        `};

        background: url('/resources/images/icons/throbber.png');
        background-size: auto 100%;    
        animation: throbbingFrames ${throbDuration}s ${throbWait}s forwards infinite;
        animation-timing-function: steps(${throbFramesUsed});
    }
`;

const Throbber: React.FC<{active: boolean}> = (props) => {

    return (
        <StyledThrobberContainer active={props.active}>
            <StyledThrobber active={props.active} />
        </StyledThrobberContainer>
    )
}

export default Throbber;